@import '@/sass/variables.sass';
@import '~vuetify/src/styles/styles.sass';

@import "~vuejs-noty/dist/vuejs-noty.css";

.noty_body {
  font-family: "Roboto" !important;
}

.min-vh-100 {
  min-height: 90vh !important;
}
